@import "app";

html {
    font-size: 16px;

    @media (max-width: 767px) {
        font-size: 12px;
    }
}

body {
    font-family: "Lato", sans-serif;
    background-color: #f5f5f5;
    color: #808080;
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.btn-text-white {
    color: white;
}

.login-container {
    min-height: 600px;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

p,
a,
span {
    font-size: 1rem;
    line-height: 1.6em;
    margin: 0;
    padding: 0;
    color: #808080;
}

h5 {
    font-size: 1.25rem;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
}

h4 {
    font-size: 1.5rem;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
}

h3 {
    font-size: 2rem;
    // font-family: 'Roboto Slab', serif;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 2.5rem;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 3rem;
    line-height: 1.25em;
    margin: 0;
    padding: 0;
}

a:hover {
    cursor: pointer;
    text-decoration: none;
}

img {
    width: 100%;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.btn {
    border: $blue 2px solid;
    background: $blue;
    width: 30%;
    border-radius: 10px;
    padding: 1.25rem 0.75rem;
    line-height: 0;
    width: auto;

    @media (max-width: 767px) {
        width: 100%;
    }

    &:focus {
        border-color: transparent;
        box-shadow: none;
    }

    &:hover {
        color: $blue;
        border-color: $blue;
        background: #fff;
    }

    &.btn-login {
        // width: 100%;
        color: #fff;
        border-color: #fff;
        border-radius: 5px;

        &:hover {
            background: #fff;
            color: $blue;
        }
    }

    &.btn-add-new {
        display: flex;
        align-items: center;
        padding: 1%;
        border-radius: 0;
        margin-left: 10px;
        background: #fff;
        color: $blue;
        width: 70%;

        @media (max-width: 767px) {
            margin-left: 0;
            justify-content: center;
        }

        .material-icons {
            margin-right: 0.5em;
            color: $blue;
        }

        &:hover {
            background: $blue;
            color: #fff;

            .material-icons {
                color: #fff;
            }
        }
    }

    &.btn-submit {
        color: #fff;

        &:hover {
            color: $blue;
        }
    }

    &.btn-cancel {
        border-color: $blue;
        color: $blue;
        background: #fff;

        &:hover {
            border-color: $blue;
            color: #fff;
            background: $blue;
        }
    }

    &.btn-sort,
    &.btn-status,
    &.btn-edit {
        color: $blue;
        border: none;
        background: none;
        font-weight: 600;
        padding-left: 0;
        &:hover {
            color: #808080;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &.btn-edit {
        font-weight: 400;
    }

    &.logout {
        border-color: $blue;
        color: $indigo;
        background: #fff;
        &:hover {
            background: $blue;
            color: #fff;
        }
    }

    &.btn-danger {
        background-color: #e3342f;
        border-color: #e3342f;
        color: white;
        &:hover {
            background-color: #c82b25;
        }
    }

    &.btn-success {
        background-color: #38c172;
        border-color: #38c172;
        color: white;
        &:hover {
            background-color: #30a862;
        }
    }

    &.btn-warning {
        background-color: #f3dc3e;
        border-color: #f3dc3e;
        color: white;
        &:hover {
            background-color: #dec523;
        }
    }

    &.btn-reject {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;

        &:hover {
            color: #fff;
            background-color: #5c636a;
            border-color: #565e64;
        }
    }
}

//upload button
.btn-upload {
    position: relative;
    padding: 0;
    border: none;
    background: transparent;
    width: 100%;
    height: 45px;
    border-bottom: 2px solid $blue;
    border-radius: 0;
}

//upload button ends

// alert

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

a.close.disabled {
    pointer-events: none;
}
// alert ends

// custom select

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.6em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    &.notselecttag {
        max-width: 120px;
    }
}

.custom-select:focus,
.custom-select:focus-within {
    border-color: #a1cbef;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 144, 220, 0.25);
}

.custom-select:focus::-ms-value,
.custom-select:focus-within::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.7875rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.125rem;
}
// custom select ends

nav {
    margin-bottom: 1%;

    .navbar {
        padding: 0;
        margin: 0;
    }

    .navbar-brand .mainlogo {
        // width: 120px;
        // position: absolute;
        // top: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: 0.33s linear;
        z-index: 10;

        @media (max-width: 767px) {
            width: 100px;
            margin: 0;
        }

        img {
            width: 100%;
        }
    }
}

.login-page {
    margin: 5% 0;
    color: #fff;
    max-height: 1000px;

    .panel {
        background: $blue;
        padding: 10%;
    }

    h3 {
        text-align: center;
        margin-bottom: 1em;
    }
}

.side-bar {
    background: $blue;
    padding-bottom: 5%;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        width: 100%;
        color: #fff;
        padding: 8% 0 8% 10%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.105);

        @media (max-width: 767px) {
            padding-top: 3%;
            padding-bottom: 3%;
        }
    }

    li:hover {
        background: rgba(255, 255, 255, 0.105);
        cursor: pointer;
    }

    a:last-child li {
        border-bottom: none;
    }
}

.product-table {
    padding-bottom: 5%;
}

.backend-form {
    padding-bottom: 5%;

    h4 {
        margin-bottom: 2em;
        color: $blue;
    }

    input[type="file"] {
        border: none;
        cursor: pointer;
        background: none;
        padding: 0;
        border-bottom: 1px solid $blue;
        border-radius: 0;
        height: 44px;
    }

    tr[id^="product-row"].manipulated {
        cursor: pointer;
    }

    .enabled-disabled {
        display: flex;
        align-items: center;

        label {
            margin-bottom: 0;
            margin-right: 2rem;
        }
    }

    .form-group {
        margin-bottom: 2rem;

        span {
            font-size: 0.7rem;
            font-style: italic;
            letter-spacing: 0.5px;
        }

        label.required:after {
            color: red;
            content: " *";
        }
    }

    .form-control {
        border: 2px solid $blue;
        //   padding: 20px;

        &:focus {
            // border-color: transparent;
            box-shadow: none;
        }

        &.select-option {
            height: 44px;
        }

        &.error {
            border-color: $red !important;
        }
    }

    .list-to-check {
        border-bottom: 1px solid #e7e7e7;
        padding: 0.5em;

        &:nth-last-child(odd) {
            padding-left: 1.5em;
        }

        label {
            margin: 0;
            display: block;
            margin-left: 20px;
        }
        input {
            float: left;
            margin-left: -20px;
            margin-right: 7px;
            margin-top: 6px;
        }
    }
}

.backend-table {
    padding-bottom: 5%;

    .dataTables_wrapper {
        .dataTables_length {
            font-family: "Lato", sans-serif;

            select {
                height: 35px;
                margin: 0 0.5em;
            }
        }

        .dataTables_filter {
            font-family: "Lato", sans-serif;

            input {
                border: 1px solid rgb(166, 166, 166);
                padding: 5px;
                border-radius: 5px;
            }
        }

        table.dataTable {
            font-family: "Lato", sans-serif;
            margin-bottom: 20px;
            padding-top: 20px;
            thead {
                color: $blue;
            }

            &.display tbody td {
                border-top: none;
            }

            &.display tbody tr.odd {
                background: #fff;
            }

            &.display tbody tr.even {
                background: #f7f7f7;
            }

            &.display tbody tr.odd > .sorting_1,
            &.display tbody tr.even > .sorting_1 {
                background: none;
            }
        }

        .dataTables_info {
            font-family: "Lato", sans-serif;
        }

        .dataTables_paginate {
            font-family: "Lato", sans-serif;

            .paginate_button.current {
                background: #fff;
                border: none;

                &:hover {
                    border: none;
                    border-bottom: 2px solid $blue;
                    border-radius: 0;
                    background: none;
                }
            }

            .paginate_button {
                font-size: 0.9rem;
            }

            span {
                &:nth-child(2),
                &:nth-child(3) {
                    &.paginate_disabled_next,
                    &.paginate_enabled_next,
                    &.paginate_disabled_previous,
                    &.paginate_enabled_previous {
                        padding: 0.5rem;
                    }

                    &.paginate_enabled_next,
                    &.paginate_enabled_previous {
                        cursor: pointer;
                        &:hover {
                            color: #54bbef;
                        }
                    }

                    &.paginate_disabled_previous,
                    &.paginate_enabled_previous {
                        margin-right: 0.5rem;
                    }
                }

                &:nth-child(1),
                &:nth-child(4) {
                    &.paginate_disabled_next,
                    &.paginate_enabled_next,
                    &.paginate_disabled_previous,
                    &.paginate_enabled_previous {
                        display: none;
                    }
                }
            }
        }

        td {
            padding-left: 18px !important;
            padding-right: 18px !important;
        }

        td.rearrange {
            form {
                width: 50%;
                float: left;
            }
        }
    }

    .table-class,
    .table-class-server {
        width: 100% !important;
    }
    .menu-container {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 5%;

        @media (max-width: 767px) {
            flex-flow: column;
        }

        .show-menu {
            border-bottom: 2px solid $blue;
            padding: 1%;
            margin: 0 10px;
            color: $blue;
            width: 100%;
            text-align: center;

            @media (max-width: 767px) {
                margin: 10px 0;
            }

            &:hover {
                background: $blue;
                color: #fff;
            }

            &.active {
                background: $blue;
                border: 2px solid $blue;
                color: #fff;
            }
        }

        .show-menu:first-child {
            margin-left: 0;
        }
        .btn-add-new {
            width: 100%;
        }
    }
}

.article-category-table,
.product-category-table {
    .menu-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.help-block {
    color: #fff;
    text-transform: uppercase;
    font-weight: 100;
}

.input-payment-container,
.input-dp-container {
    background-color: #a7c8e9;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: -2px 2px 8px -1px rgb(0 0 0 / 20%);
}

.price {
    display: flex;

    & > :first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    & > :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .suffix {
        width: 9%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        border-right: none;
        color: #282c36;
        border: 2px solid #4790dc;
    }

    input {
        width: 99%;
        border: 2px solid #4790dc;
        border-left: none;
        padding-left: 1rem;
        padding-right: 1rem;
    }

}

.accordion-button.no-arrow::after {
    display: none;
}

.approval-card {
    border-radius: 0.5rem;
    color: #000000;

    & > .card-header {
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background-color: #eaf0f9;
    }

    & .approval-card-footer {
        display: flex;
        justify-content: space-between;

        & > div {
            width: fit-content;

            & form {
                display: inline-block;
            }
        }

        & .updated-date {
            & p {
                font-size: 14px;
            }
        }
    }
}

.toggle {
    --width: 160px;
    --height: calc(var(--width) / 4);

    position: relative;
    display: inline-block;
    width: var(--width);
    height: var(--height);
    border-radius: 5px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;

    input {
        display: none;

        &:checked ~ .labels::after {
            transform: translateX(var(--width));
        }

        &:checked ~ .labels::before {
            transform: translateX(var(--width));
        }
    }

    .labels {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-family: sans-serif;
        transition: all 0.4s ease-in-out;
        overflow: hidden;

        &::after {
            content: attr(data-off);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            color: #ffffff;
            background-color: #4790dc;
            transition: all 0.4s ease-in-out;
        }

        &::before {
            content: attr(data-on);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: calc(var(--width) * -1);
            height: 100%;
            width: 100%;
            color: #4790dc;
            background-color: #ffffff;
            text-align: center;
            transition: all 0.4s ease-in-out;
        }
    }
}

.month_year_datepicker {
    .ui-datepicker-calendar {
        display: none;
    }
}

.upload-products-label {
    position: relative;

    & input {
        visibility: hidden;
        position: absolute;
        top: 0;
        height: 0px;
        width: 0px;
    }
}

.hidden {
    visibility: hidden;
}

.import-product-container {
    & .row {
        white-space: nowrap;
        overflow-x: auto;
        display: flex;

        @media screen and (min-width: 992px) {
            display: block;
            text-align: center;
        }

        & > .status-category-wrapper {
            display: inline-block;
            white-space: normal;
            border-radius: 8px;
            margin-bottom: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            text-align: start;

            @media screen and (min-width: 992px) {
                width: 25rem;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }

            @media screen and (max-width: 992px) {
                margin-top: 0.5rem;
            }

            & > .scrollable-wrapper {
                @media screen and (min-width: 992px) {
                    height: 35rem;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 18px;
                    }

                    &::-webkit-scrollbar-track {
                        display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-clip: padding-box;
                        border: 4px solid rgba(0, 0, 0, 0);
                    }

                    &.update {
                        &::-webkit-scrollbar-thumb {
                            background-color: #9fc7e7;
                            &:hover {
                                background-color: #0b6cb9;
                            }
                        }
                    }

                    &.new {
                        &::-webkit-scrollbar-thumb {
                            background-color: #d7f3e3;
                            &:hover {
                                background-color: #099f49;
                            }
                        }
                    }

                    &.error {
                        &::-webkit-scrollbar-thumb {
                            background-color: #f9d6d5;
                            &:hover {
                                background-color: #a10000;
                            }
                        }
                    }
                }

                & > .accordion {
                    & > .accordion-item {
                        & > .accordion-collapse {
                            padding: 0.5rem 0;

                            &[id^="flush-collapse-new"] {
                                background-color: #41c172;
                            }

                            &[id^="flush-collapse-update"] {
                                background-color: #4790dc;
                            }

                            &[id^="flush-collapse-error"] {
                                background-color: #e3342f;
                            }

                            & > .product-content {
                                border-radius: 8px;
                                padding: 0.5rem;
                                background-color: white;
                                margin-bottom: 1rem;

                                & > table {
                                    table-layout: fixed;
                                    padding: 0.5rem;

                                    & td {
                                        overflow: auto;
                                        padding-top: 2px;
                                        padding-bottom: 2px;

                                        &::-webkit-scrollbar {
                                            height: 8px;
                                        }

                                        &::-webkit-scrollbar-track {
                                            display: none;
                                        }

                                        &::-webkit-scrollbar-thumb {
                                            border-radius: 10px;
                                            background-color: #c1c1c1;

                                            &:hover {
                                                background-color: #7b7b7b;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

tr {
    &.transparent-border {
        & > td {
            border-color: transparent;
        }
    }

    &.bolder-border {
        & > td,
        & > th {
            border-color: #212529;
            border-bottom-width: 2px;
        }
    }
}

.nowrap {
    white-space: nowrap;
}

%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: rgb(210, 210, 210);
    border-color: rgb(210, 210, 210);
    border-radius: 2px;
    &::placeholder {
        color: transparent;
    }
}
@keyframes loading-skeleton {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}
.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;

    img {
        filter: grayscale(100) contrast(0%) brightness(1.8);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    .btn,
    label,
    .form-control {
        @extend %loading-skeleton;
    }
}

.file-preview {
    position: relative;

    & > a {
        height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e7e7e7;
        border-radius: 0.25rem;
        padding: 0.375rem 0.75rem 0.2rem 0.75rem;
        font-size: 0.9rem;
        line-height: 1.6;
        font-weight: 400;
        text-align: center;

        & > img {
            height: 60%;
            width: fit-content;
        }

        & > p {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }
    }

    & .file-action-icon:nth-child(2) {
        transform: translateX(65px);
    }

    & .file-action-icon:nth-child(3) {
        transform: translateX(105px);
    }

    & .file-action-icon:nth-child(4) {
        transform: translateX(145px);
    }

    & .file-action-icon {
        background-color: white;
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        height: fit-content;
        display: flex;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 100%;
        padding: 7px 7px 5px 6px;
        border: none;
        box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 75%);
        cursor: pointer;

        & span {
            font-weight: bolder;
            font-size: 1rem;
            color: #bbbbbb;
        }

        &.warning,
        &.success,
        &.danger {
            box-shadow: none;
            & span {
                color: white;
            }
        }

        &.warning {
            background-color: #f3dc3e;
        }

        &.success {
            background-color: #00bf77;
        }

        &.danger {
            background-color: #f22b2e;
        }

        &:hover {
            box-shadow: none;
            & span {
                color: white;
            }
            &.hover-success {
                background-color: #00bf77;
            }
            &.hover-warning {
                background-color: #f3dc3e;
            }
            &.hover-danger {
                background-color: #f22b2e;
            }
        }
    }
}

.hover-darken {
    &:hover {
        filter: brightness(50%);
    }
}

.bg-primary-light {
    background-color: #d5ecff !important;
}

.form-check-input.three-state[type="checkbox"]:indeterminate {
    background-color: #00bf77;
    border-color: #00bf77;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.checkbox-legend {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 0.25em;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-color: #babbbd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

    &.success {
        background-color: #00bf77;
        border-color: #00bf77;
    }

    &.primary {
        background-color: #3490dc;
        border-color: #3490dc;
    }
}

.select2-dropdown {
    z-index: 10000;
}

.spinner-wrapper {
    text-align: center;
}

.vertical-align-middle {
    vertical-align: middle;
}

// footer {
//     background: #333333;
//     padding: 1% 0;

//     p.footer {
//         font-size: 0.8rem;
//         color: #f7f6f7;
//         text-align: center;
//     }
// }
