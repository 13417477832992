/* (A) FULL SCREEN WRAPPER */
#spinner {
  position: fixed;
  top: 0; left: 0;
  width: 100vw; height: 100vh;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
}

/* (B) CENTER LOADING SPINNER */
#spinner img {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%);
  width: 50px;
}

/* (C) SHOW & HIDE */
#spinner {
  visibility: hidden;
  opacity: 0;
}
#spinner.show {
  visibility: visible;
  opacity: 1;
}

/* (X) DOES NOT QUITE MATTER */
html, body {
  margin: 0;
  padding: 0;
}
